import React, { useState } from "react";
import { useMutation } from "react-query";
import { CategoryImageicon } from "../../../assets/images";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { resetPersistedState } from "../../../redux/authSlice/authSlice";

const RemoveAccount = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const mutation = useMutation(
    "deleteAccount",
    async () => {
      try {
        const response = await axiosInstance.post(
          API_URL.REMOVE_ACCOUNT,
          null,
          {
            headers: {
              version: "v2",
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error("Error removing account");
      }
    },
    {
      onSuccess: () => {
        // localStorage.clear();
        // navigate("/");
        // window.location.reload();
        clearAllCookies();
        dispatch(resetPersistedState());
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      },
    }
  );

  const clearAllCookies = () => {
    const allCookies = Cookies.get();

    Object.keys(allCookies).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  };

  const handleYesClick = () => {
    setIsConfirmationModalOpen(true);
  };
  const handleNoClick = () => {
    navigate("/");
  };
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleRemoveAccount = (mutation: any) => {
    mutation.mutate();
  };

  return (
    <div className="col-md-12 pt-5">
      <div className="sub-div-headings d-flex pt-3">
        <img src={CategoryImageicon} alt="CategoryImageicon" />
        &nbsp;
        <span>Remove Account</span>&nbsp;
        <span className="line-span">
          <div className="line-div-max-length-ecommerce"></div>
        </span>
      </div>
      <div className="row innerdiv-center">
        <div>
          <div className="inner-text-heading-two">
            Would you like to remove your account ?
          </div>
          <div className="row pt-2">
            <div className="col">
              <button
                className="btn btn-danger px-3"
                onClick={() => handleYesClick()}
              >
                Yes
              </button>
              &nbsp;
              <button className="btn btn-success px-3" onClick={handleNoClick}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {isConfirmationModalOpen && (
        <div className="address-confirmation-modal">
          <div className="address-modal-content">
            <div className="inner-text-heading-two">
              Are you sure you want to remove your account?
            </div>
            <div className="address-modal-actions">
              <button
                className="btn btn-danger"
                onClick={() => handleRemoveAccount(mutation)}
              >
                Yes
              </button>
              <button
                className="btn btn-secondary"
                onClick={closeConfirmationModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RemoveAccount;
