import React, { useEffect, useState } from "react";
import {
  CategoryImageicon,
  SpecialOfferIcon,
  fish_thorny,
  homepagefishimg,
} from "../../../assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
interface Product {
  out_of_stock: any;
  vendor_product_inventories: any;
  weight: string;
  product_id: string;
  product_name: string;
  category_name: string;
  business_area: string;

  product_details?: {
    number_of_pieces: string;
    images?: string[];
    unit: string;
    measure: string;
  };
  variants?: {
    price?: number;
  }[];
}

const Favourites = () => {
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 932 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 932, min: 568 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 2,
    },
  };

  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  // const { longitude, latitude } = useSelector(
  //   (state: any) => state.location.mapRegion
  // );

  const { data: fishProducts } = useQuery({
    queryKey: ["specialOffers", location],
    queryFn: async () => {
      if (location) {
        const response = await axiosInstance.get(
          `${API_URL.FAVOURITES_LIST}?customerLongitude=${location.longitude}&customerLatitude=${location.latitude}`,
          { headers: { version: "v2" } }
        );
        return response.data;
      }
      return null;
    },
    enabled: !!location.longitude && !!location.latitude,
  });

  const allFishProducts = fishProducts?.content || [];

  const handleImageClick = (product_id: string, business_area: string) => {
    navigate(`/ProductDetails?Planet=${business_area}&id=${product_id}`, {
      state: { product_id },
    });
  };

  const convertWeightToKg = (weight: any) => {
    return Number(weight) >= 1000 ? `${weight / 1000}Kg` : `${weight}g`;
  };

  return (
    <>
      {allFishProducts && allFishProducts.length > 0 && (
        <div className="col-md-12 pt-1">
          <div className="">
            <div className="sub-div-headings d-flex">
              {/* <img src={SpecialOfferIcon} alt="SpecialOfferIcon" /> */}
              <div>
                <FontAwesomeIcon
                  icon={faHeart}
                  className="icon-hover"
                  color="#28AF4B"
                />
              </div>
              &nbsp;
              <span>Favourites</span>&nbsp;
              <span className="line-span">
                <div className="line-div-max-length-ecommerce"></div>
              </span>
            </div>
            <div className="col-md-12 cart-container div-caroysel-relative  pt-4">
              <div className="row pt-3">
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  rewind={true}
                >
                  {/* maping start  */}
                  {allFishProducts?.map((product: Product) => (
                    <div
                      className="pt-2 mb-3 content-fishcard fish-card-div-height px-2"
                      key={product.product_id}
                    >
                      {/* <div className="card stock-card card-no-border card-hghlght-reverse"> */}
                      <div
                        className={`card   ${
                          product.out_of_stock
                            ? "stock-card-out-of-stock"
                            : "stock-card card-hghlght-reverse product-card-cat"
                        }`}
                      >
                        <div className="card-body ">
                          <div className="card-img-div-top">
                            <img
                              className={`card-img-top ${
                                product.out_of_stock
                                  ? "out-of-stock desaturate"
                                  : ""
                              }`}
                              src={
                                product?.product_details?.images?.[0] ??
                                fish_thorny
                              }
                              alt="product image"
                              onClick={() =>
                                handleImageClick(
                                  product?.product_id,
                                  product?.business_area
                                )
                              }
                            />
                          </div>
                          <div className="card-inner-text-div">
                            <div className="inner-text-heading-four one-line-headings">
                              {product?.product_name}
                            </div>
                            <div className="inner-text-content-five-font-12">
                              {product?.category_name}
                            </div>
                            {/* <div className="inner-text-content-five-font-12">
                              <div className="">{product?.category_name}</div>

                              <span className="">{product?.weight}gm</span>
                              {product?.product_details?.number_of_pieces && (
                                <>
                                  <span className="font-color-gray">
                                    &nbsp;|&nbsp;
                                  </span>
                                  <span className="font-color-gray">
                                    {product.product_details.number_of_pieces}
                                  </span>
                                </>
                              )}
                            </div> */}
                            {product?.business_area === "meatPlanet" && (
                              <div className="inner-text-content-five-font-12 font-color-gray">
                                {product?.product_details?.measure}
                                {product?.product_details?.unit}
                              </div>
                            )}
                            {product?.business_area === "fishPlanet" && (
                              <div className="inner-text-content-five-font-12">
                                <span className="">
                                  {convertWeightToKg(product?.weight)}
                                  &nbsp;net
                                </span>
                                {product?.product_details?.number_of_pieces && (
                                  <>
                                    <span className="font-color-gray">
                                      &nbsp;|&nbsp;
                                    </span>
                                    <span className="font-color-gray">
                                      {
                                        product.product_details
                                          ?.number_of_pieces
                                      }
                                    </span>
                                  </>
                                )}
                              </div>
                            )}
                            <div className="inner-text-content-five">
                              Price :
                              <span className="inner-text-heading-four">
                                {" "}
                                ₹
                                {product?.vendor_product_inventories?.[0]
                                  ?.price ?? 0}
                              </span>
                            </div>
                          </div>
                          {product.out_of_stock ? (
                            <div className="out-of-stock-text">
                              <button className=" outofstock">
                                Out of Stock
                              </button>
                            </div>
                          ) : (
                            <>
                              <div className="mb-0 text-end">
                                <button
                                  className=" add-to-cart-button "
                                  onClick={() =>
                                    handleImageClick(
                                      product?.product_id,
                                      product?.business_area
                                    )
                                  }
                                >
                                  View
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Favourites;
