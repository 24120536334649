import React, { useState } from "react";
import { CategoryImageicon } from "../../../assets/images";
import { useDispatch } from "react-redux";
import { resetPersistedState } from "../../../redux/authSlice/authSlice";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const LogOut = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleLogout = () => {
    // setIsOpen(!isOpen);
    // dispatch(resetPersistedState());
    // localStorage.clear();
    // navigate("/");
    // window.location.reload();
    setIsConfirmationModalOpen(true);
  };
  const handleLogoutNo = () => {
    // sessionStorage.clear();
    navigate("/");
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleLogOutAccount = () => {
    clearAllCookies();
    dispatch(resetPersistedState());
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const clearAllCookies = () => {
    const allCookies = Cookies.get();

    Object.keys(allCookies).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  };

  return (
    <div className="col-md-12 pt-5">
      <div className="sub-div-headings d-flex pt-3">
        <img src={CategoryImageicon} alt="CategoryImageicon" />
        &nbsp;
        <span>LogOut</span>&nbsp;
        <span className="line-span">
          <div className="line-div-max-length-ecommerce"></div>
        </span>
      </div>
      <div className="row innerdiv-center">
        <div>
          <div className="inner-text-heading-two">
            Would you like to logout your account ?
          </div>
          <div className="row pt-2">
            <div className="col">
              <button className="btn btn-danger px-3" onClick={handleLogout}>
                Yes
              </button>
              &nbsp;
              <button className="btn btn-success px-3" onClick={handleLogoutNo}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {isConfirmationModalOpen && (
        <div className="address-confirmation-modal">
          <div className="address-modal-content">
            <div className="inner-text-heading-two">
              Are you sure you want to logout your account?
            </div>
            <div className="address-modal-actions">
              <button className="btn btn-danger" onClick={handleLogOutAccount}>
                Yes
              </button>
              <button
                className="btn btn-secondary"
                onClick={closeConfirmationModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogOut;
