import React, { useState, useEffect } from "react";
import {
  CategoryImageicon,
  SpecialOfferIcon,
  fish_demo,
  fish_thorny,
  homepagefishimg,
} from "../../../assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axios.instance";
import { API_URL, mapurl } from "../../../services/apiConfig";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import productListSlice, {
  setLocation,
} from "../../../redux/productList/productListSlice";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFireFlameCurved } from "@fortawesome/free-solid-svg-icons";

const DryFishhome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 932 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 932, min: 568 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 2,
    },
  };

  interface Product {
    out_of_stock: any;
    vendor_product_inventories: any;
    weight: string;
    product_id: string;
    product_name: string;
    category_name: string;
    business_area: string;
    badge?: {
      badge_type: string;
      badge_label: string;
    };
    product_details?: {
      number_of_pieces: string;
      images?: string[];
    };
    variants?: {
      price?: number;
    }[];
  }

  interface Coordinates {
    latitude: number;
    longitude: number;
  }

  const [currentLocation, setCurrentLocation] = useState<Coordinates | null>(
    null
  );

  const { customerId } = useSelector((state: any) => state.auth);
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );
  const categoryMerchant = useSelector(
    (state: any) => state?.merchant?.selectedMerchantId
  );

  // const { data: fishMerchants, isLoading: merchantLoading } = useQuery({
  //   queryFn: () => {
  //     return new Promise((resolve, reject) => {
  //       if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             const latitude = position.coords.latitude;
  //             const longitude = position.coords.longitude;

  //             setCurrentLocation({ latitude, longitude });
  //             resolve(
  //               axiosInstance.get<any>(
  //                 `${API_URL.FISH_MERCHANT}/${latitude}/${longitude}`
  //               )
  //             );
  //           },
  //           () => {
  //             console.log("Unable to retrieve your location");
  //             reject();
  //           }
  //         );
  //       } else {
  //         console.log("Geolocation not supported");
  //         reject();
  //       }
  //     });
  //   },
  //   enabled: true,
  // });

  // const { data: fishMerchantsList, isLoading: merchantIdLoading } = useQuery({
  //   queryFn: () => {
  //     if (currentLocation) {
  //       let location = {
  //         latitude: currentLocation.latitude,
  //         longitude: currentLocation.longitude,
  //       };

  //       return axiosInstance.get<any>(
  //         `${API_URL.FISH_MERCHANT}/${currentLocation.latitude}/${currentLocation.longitude}`
  //       );
  //     }
  //     return null;
  //   },
  //   queryKey: ["getFishMerchant", currentLocation?.latitude],
  //   enabled: !!currentLocation?.latitude,
  // });
  // useEffect(() => {
  //   if (currentLocation) {
  //     getAddress();
  //   }
  // }, [currentLocation]);
  // const getAddress = async () => {
  //   if (!currentLocation) {
  //     console.error("Location is not available");
  //     return;
  //   }

  //   try {
  //     const { data } = await axios.get(
  //       `${mapurl}reverse?format=json&lat=${currentLocation.latitude}&lon=${currentLocation.longitude}`
  //     );
  //     // const location = {
  //     //   latitude: currentLocation.latitude,
  //     //   longitude: currentLocation.longitude,
  //     //   formattedAddress: data.display_name,
  //     // };
  //     // dispatch(setLocation(location));
  //   } catch (error) {
  //     console.error("Error occurred:", error);
  //   }
  // };

  // const merchantId = fishMerchantsList?.data?.merchant_info?.merchant_id;

  // console.log(
  //   "Location and merchant",
  //   categoryMerchant,
  //   "-----",
  //   merchantId,
  //   "location ",
  //   location
  // );

  const {
    data: fishProducts,
    error,
    isLoading: productsLoading,
  } = useQuery(
    "getProducts",
    async () => {
      try {
        const response = await axiosInstance.post<any>(
          API_URL.PRODUCT_LIST,
          {
            index: "0",
            count: "500",
            country: "ALL",
            merchant_id: categoryMerchant,
            business_area: "fishPlanet",
            latitude: location.latitude,
            longitude: location.longitude,
            is_parent_category: true,
            category_id: "ALL",
            customer_id: customerId,
          }
          // {
          //   headers: { version: "v8" },
          // }
        );
        return response.data;
      } catch (error) {
        throw new Error("Error fetching products");
      }
    },
    {
      enabled: !!location?.latitude && !!categoryMerchant,
    }
  );

  const allFishProducts = fishProducts?.content || [];

  const handleImageClick = (product_id: string, business_area: string) => {
    navigate(`/ProductDetails?Planet=${business_area}&id=${product_id}`, {
      state: { product_id },
    });
  };

  return (
    <div className="col-md-12 pt-4">
      <div className="">
        {allFishProducts && allFishProducts.length > 0 && (
          <div className="sub-div-headings d-flex">
            <img src={SpecialOfferIcon} alt="SpecialOfferIcon" />
            &nbsp;
            <span>Top products in Fresh Fish</span>&nbsp;
            <span className="line-span">
              <div className="line-div-max-length-ecommerce"></div>
            </span>
          </div>
        )}
        <div className="col-md-12 cart-container  pt-4 div-caroysel-relative">
          <div className="row pt-3">
            <Carousel responsive={responsive} swipeable={true} rewind={true}>
              {/* maping start  */}
              {allFishProducts
                .filter(
                  (product: { out_of_stock: false }) => !product.out_of_stock
                )
                .map((product: Product) => (
                  <div
                    className="pt-2 mb-3 content-fishcard fish-card-div-height px-2"
                    key={product.product_id}
                  >
                    <div className="card stock-card card-no-border card-hghlght-reverse">
                      <div className="card-body relative-div-bg">
                        {product?.badge?.badge_type && (
                          <div className="product-badge-div ">
                            <FontAwesomeIcon icon={faFireFlameCurved} />
                            &nbsp;
                            {product?.badge?.badge_label}
                          </div>
                        )}
                        <div className="card-img-div-top">
                          <img
                            className={`card-img-top ${
                              product.out_of_stock
                                ? "out-of-stock desaturate"
                                : ""
                            }`}
                            src={
                              product?.product_details?.images?.[0] ?? fish_demo
                            }
                            alt="product image"
                            onClick={() =>
                              handleImageClick(
                                product?.product_id,
                                product?.business_area
                              )
                            }
                          />
                        </div>
                        <div className="card-inner-text-div">
                          <div className="inner-text-heading-four one-line-headings">
                            {product?.product_name}
                          </div>
                          <div className="inner-text-content-five-font-12">
                            <div className="">{product?.category_name}</div>

                            <span className="">{product?.weight}gm</span>
                            {product?.product_details?.number_of_pieces && (
                              <>
                                <span className="font-color-gray">
                                  &nbsp;|&nbsp;
                                </span>
                                <span className="font-color-gray">
                                  {product.product_details.number_of_pieces}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="inner-text-content-five ">
                            Price :
                            <span className="inner-text-heading-four">
                              {" "}
                              ₹
                              {product?.vendor_product_inventories?.[0]
                                ?.price ?? 0}
                            </span>
                          </div>
                        </div>
                        {product.out_of_stock ? (
                          <div className="out-of-stock-text">
                            <button className=" outofstock">
                              Out of Stock
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="mb-0 text-end">
                              <button
                                className=" add-to-cart-button "
                                onClick={() =>
                                  handleImageClick(
                                    product?.product_id,
                                    product?.business_area
                                  )
                                }
                              >
                                View
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DryFishhome;
