import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
const baseUrl = process.env.REACT_APP_BASE_URL;
const mapurl = "https://map.livelocal.co.in/";

const accessToken = localStorage.getItem("accessToken");
const vendorId = localStorage.getItem("vendorId");
const guestId = Cookies.get("guest-id");
const sessionData = Cookies.get("session-id");
let sessionId: string | null;
if (sessionData) {
  const { uuid } = JSON.parse(sessionData);
  sessionId = uuid;
}

let customerId;
if (accessToken) {
  const decodedToken: any = jwtDecode(accessToken);
  customerId = decodedToken["customer-id"];
}
const API_URL = {
  PRODUCT_LIST: `/product-merchant/master/products/by-category/by-merchant`,
  FISH_MERCHANT: `/customer-consumer/customer/service/master/merchants-by-coordinates/fishPlanet`,
  CAROUSEL_LIST: `/customer-consumer/customer/service/carousal-configs/planet/fishPlanet`,
  ADDRESS_LIST: `/customer-consumer/customer/service/master/deliveryaddress`,
  SEND_OTP: `/customer-consumer/customer/service/otp/login_Register_sendotp`,
  VERIFY_OTP: `/customer-consumer/authentication/login_or_register`,
  ACCOUNT_LOGIN_DETAILS: `/customer-consumer/customer/service/consumer/account-settings`,
  ACCOUNT_ADDRESS_DETAILS: `/customer-consumer/customer/service/master/deliveryaddress`,
  CART_ITEM_ENCRYPTION: `/customer-consumer/customer/service/consumers/${customerId}/insert-cart`,
  GET_CART_ITEM_ENCRYPTION: `/customer-consumer/customer/service/consumers/${customerId}/carts`,
  CalculateDeliveryRate: `/order-consumer/order/service/deliveryfee/calculate-delivery-fee`,
  locationUrl: "https://osrm.livelocal.co.in/",
  PREVIOUS_ORDERS: `/order-consumer/order/service/consumers/orders/last-five-product/${customerId}`,
  FRESH_FISH_HOME: `/product-merchant/master/get_categories`,
  // FISH_CATEGORY_LIST:`/product-consumer/admin/master/nearest-merchant/parent-categories`,
  SINGLE_PRODUCT_LIST: `/product-consumer/admin/master/full-products`,
  LAST_FIVE_PRODUCT: `/order-consumer/order/service/master/last-five-product`,
  DELIVERY_SLOT:
    "/order-consumer/order/service/master/get-consumer-delivery-slots",
  DELIVERY_FEE: `/order-consumer/order/service/deliveryfee/delivery-fee/calculate?customerId=${customerId}`,
  DELIVERY_ADDRESS_DELETE: `/customer-consumer/customer/service/master/deliveryaddress`,
  UPDATE_CART: `/customer-consumer/customer/service/consumers/${customerId}/update-cart`,
  ORDER_CREATION: `/order-consumer/order/service/consumers/orders/create`,
  FAQ_LIST: `/customer-consumer/customer/service/faq/get/all`,
  REMOVE_ACCOUNT: `/customer-consumer/authentication/delete-user/${customerId}`,
  MY_ORDERS: `/order-consumer/order/service/consumers/orders/customer`,
  PASTORDERSDETAIL: `/order-consumer/order/service/consumers/orders`,
  DOWNLOAD_ORDER_INVOICE: `order-consumer/order/service/consumers/orders/invoice`,
  UPDATE_CART_GUEST: `/customer-consumer/customer/service/guest/update-guest-cart`,
  CAROUSEL_LIST_FOOD: `/customer-consumer/customer/service/carousal-configs/planet/foodPlanet`,
  FOOD_MERCHANTS: `/customer-consumer/customer/service/master/merchants`,
  MERCHANT_PRODUCT_LIST: `/product-consumer/admin/master/get-all/products`,
  ORDER_REVIEW: `/order-consumer/order/service/consumers/orders`,
  FOOD_CATEGORIES: `/product-consumer/quickMenu`,
  FOOD_LISTING: `/product-consumer/product/search/name/starts/byLocation`,
  PROMO_CODE_LIST: `/product-consumer/admin/master/promocodes`,
  PROMO_CODE_APPLY: `/product-consumer/validatePromoCode`,
  CLEAR_CART: `/customer-consumer/customer/service/consumers/${customerId}/carts/clear`,
  PROMO_CODE_VALIDATE: `/order-consumer/order/service/admin/promo-code-user-codes/validate-user-code`,
  SUGGESTION_SEARCH: `/product-backoffice/product/search/suggestions/byLocation`,
  RECOMMENTED_PRODUCTS: `/product-consumer/product_recommendation`,
  SPECIAL_OFFERS: `/product-consumer/admin/master/offer/DISCOUNT/merchant`,
  GROCERY_STORE_LIST: `/customer-consumer/customer/service/consumers/merchants/groceryPlanet`,
  CAROUSEL_LIST_GROCERY: `/customer-consumer/customer/service/carousal-configs/planet/groceryPlanet`,
  GROCERRY_CATOGORY_LIST: `/product-merchant/master/get_categories`,
  GROCERRY_PRODUCT_LIST: `/product-merchant/master/products/by-category/by-merchant`,
  GUEST_CREATE: `/customer-consumer/customer/service/guest/create`,
  GUEST_USER_ADD_TO_CART: `/customer-consumer/customer/service/guest/add-guest-cart`,
  GUEST_USER_UPDATE_CART: `/customer-consumer/customer/service/guest/update-guest-cart`,
  GUEST_USER_GET_CARTS: `/customer-consumer/customer/service/guest/${guestId}/carts`,
  GUEST_USER_GET_ADDRESS: `/customer-consumer/customer/service/master/delivery_address/${guestId}?isGuest=true`,
  GUEST_USER_CALCULATE_FEE: `/order-consumer/order/service/master/delivery-fee/calculate?customerId=${guestId}`,
  GUEST_USER_CLEAR_CART: `/customer-consumer/customer/service/guest/${guestId}/carts/clear`,
  GUEST_ORDER_CREATE: `/customer-consumer/customer/service/external-order/create-guest`,
  FAVOURITES_LIST: `/product-consumer/get/products/favourite`,
  TOP_PRODUCTS: `/product-consumer/product/recommemtation/top-product`,
  MEAT_MERCHANTS: `/customer-consumer/customer/service/master/merchants`,
};

export {
  baseUrl,
  API_URL,
  mapurl,
  accessToken,
  vendorId,
  jwtDecode,
  guestId,
  sessionId,
};
