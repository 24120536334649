import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import "../../components/fishCard/CartButtons/CartButtons.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  CartIcon,
  foodIcon,
  lilo_logo,
  location_Icon,
  profile_Icon,
  search_icon,
} from "../../assets/images";
import SignInModal from "../signinmodal/signInModal";
import UserConfirmModal from "../userconfirmmodal/userConfirmModal";
import LocationPopup from "../locationPopup/locationPop";
import Checkout from "../../pages/Checkout/Checkout";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
// import sha256 from "crypto-js/sha256";
// import hmacSHA512 from "crypto-js/hmac-sha512";
// import Base64 from "crypto-js/enc-base64";
import { Image } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { log } from "console";
import axiosInstance from "../../services/axios.instance";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  API_URL,
  accessToken,
  guestId,
  jwtDecode,
} from "../../services/apiConfig";
import { resetPersistedState } from "../../redux/authSlice/authSlice";

const navbarlinks = [
  {
    label: "Home",
    value: "Home",
    navlink: "/",
  },
  {
    label: "Fresh Fish",
    value: "Freshfish",
    navlink: "/FreshFish",
  },
  // {
  //   label: "Food",
  //   value: "food",
  //   navlink: "/Food",
  //   // navlink: "/ComingSoon",
  // },
  {
    label: "Poultry",
    value: "Poultry",
    // navlink: "/Food",
    navlink: "/MeatHome",
  },

  {
    label: "Organic",
    value: "organic",
    navlink: "/OrganicHome",
    // navlink: "/ComingSoon",
  },
  // {
  //   label: "Offers",
  //   value: "offers",
  //   navlink: "/",
  // },
  {
    label: "History",
    value: "history",
    navlink: "/History",
  },
];

interface NavBarTopProps {
  currentPage: string;
  setCurrentPage: any;
}

const MergedHeader: React.FC<NavBarTopProps> = ({
  currentPage,
  setCurrentPage,
}) => {
  const queryClient = useQueryClient();
  const navlocation = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isuserConfirmModalOpen, setIsuserConfirmModalOpen] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [accessTokenValid, setAccessToken] = useState("");
  const dispatch = useDispatch();
  const Toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  // const { cartList } = useSelector((state: any) => state.products);
  useEffect(() => {
    const guestId = Cookies.get("guest-id");
    const sessionId = Cookies.get("session-id");

    if (!guestId || !sessionId) {
      // Show the UserConfirmModal if either cookie is missing
      setIsGuest(true);
    }
  }, []);

  const { vendorId } = useSelector((state: any) => state.auth);
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const firstnameCustomer = useSelector((state: any) => state.name);
  const encryptedResponses = useSelector(
    (state: any) => state.checkout.encryptedDataResponse
  );
  const cartKey: any = Object.keys(encryptedResponses || {}).find((key) =>
    key.includes("mer")
  );
  const searchParams = new URLSearchParams(navlocation.search);

  const planetId = useSelector(
    (state: any) => state.products.cartList.business_area
  );
  const { cartList, totalItemCount } = useSelector((state: any) => {
    try {
      const cartListData =
        state.checkout.encryptedDataResponse[cartKey].products ?? [];

      const totalQuantity =
        cartListData.length > 0
          ? cartListData.reduce(
              (total: number, item: any) => total + item.quantity,
              0
            )
          : 0;

      // Map cartListData to extract names and prices
      const cartList = cartListData.map((item: any) => ({
        name: item.product_name,
        price: item.price,
        quantity: item.quantity,
        weight: item.weight,
      }));

      return { cartList, totalItemCount: totalQuantity };
    } catch (error) {
      console.error("Error accessing cartItemCount:", error);
      return { cartList: [], totalItemCount: 0 };
    }
  });

  const secretKey = vendorId;

  const hashedData = JSON.stringify(cartList);
  var encryptDataRequest = CryptoJS.AES.encrypt(
    `${hashedData}`,
    `${secretKey}`
  ).toString();

  const mutation = useMutation(async (encryptDataRequest: any) => {
    try {
      const resp = await axiosInstance.post(
        API_URL.CART_ITEM_ENCRYPTION,
        encryptDataRequest,
        {
          headers: {
            "Content-Type": "text/plain",
            // version: "v2",
          },
        }
      );
      return resp.data;
    } catch (error: any) {
      alert(error.data.message);
    }
  });

  const toggleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
    setIsOpen(false);
  };

  // useEffect(() => {
  //   const storedGuestId = Cookies.get("guest-id");
  //   const storedSessionId = Cookies.get("session-id");

  //   if (storedGuestId) {
  //     try {
  //       const { createdAt } = JSON.parse(storedGuestId);
  //       const creationTime = new Date(createdAt).getTime();
  //       const currentTime = new Date().getTime();
  //       const elapsedTime = currentTime - creationTime;

  //       if (elapsedTime >= 1800000) {
  //         sessionStorage.removeItem("guest-id");
  //         sessionStorage.removeItem("session-id");
  //         window.location.reload();
  //       } else {
  //         const timeoutId = setTimeout(() => {
  //           sessionStorage.removeItem("guest-id");
  //           window.location.reload();
  //         }, 1800000 - elapsedTime);
  //         return () => clearTimeout(timeoutId);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing guest-id:", error);
  //     }
  //   }

  //   if (storedSessionId) {
  //     const timeoutId = setTimeout(() => {
  //       sessionStorage.removeItem("session-id");
  //       sessionStorage.removeItem("guest-id");
  //       window.location.reload();
  //     }, 1800000);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, []);
  const postData = async () => {
    await mutation.mutateAsync(encryptDataRequest);
  };

  const toggleSignInModal = () => {
    setIsSignInModalOpen(!isSignInModalOpen);
    setIsOpen(false);
  };

  const toggleuserConfirmModal = () => {
    setIsuserConfirmModalOpen(!isuserConfirmModalOpen);
    setIsOpen(false);
  };

  const toggleLocationPopup = () => {
    setIsMapModalOpen(!isMapModalOpen);
    setIsOpen(false);
  };

  const userIsSignedIn = accessToken || guestId;
  const phoneNumber = localStorage.getItem("phoneNumber");
  const userName = localStorage.getItem("userName");

  const displayName = userName ? userName : phoneNumber;

  const clearAllCookies = () => {
    const allCookies = Cookies.get();

    Object.keys(allCookies).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  };

  const handleLogout = () => {
    setIsOpen(!isOpen);
    clearAllCookies();
    dispatch(resetPersistedState());
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const handleCartClick = () => {
    if (userIsSignedIn) {
      navigate("./Checkout");
    } else {
      toggleSignInModal();
    }
  };

  const decodedToken: any = accessToken ? jwtDecode(accessToken) : null;

  useEffect(() => {
    setIsuserConfirmModalOpen(true);
    if (!location.latitude && userIsSignedIn && !isGuest) {
      setIsMapModalOpen(true);
    }
  }, []);

  const checkTokenExpiration = () => {
    if (decodedToken && decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem("accessToken");
      queryClient.invalidateQueries("user");
      window.location.reload();
    }
  };

  useEffect(() => {
    checkTokenExpiration();
  }, [decodedToken, queryClient, navigate]);

  useEffect(() => {}, [userIsSignedIn]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggledropdown = () => setDropdownOpen((prevState) => !prevState);

  const [selecetedNav, setSelecetdNav] = useState("Home");

  const handleSetActiveNavigation = (nav: string) => {
    setSelecetdNav(nav);
    setIsOpen(false);
    setCurrentPage(nav);
  };

  useEffect(() => {
    setSelecetdNav(currentPage);
  }, [currentPage]);
  const generatePath = (currentPage: string) => {
    let path = "/checkout";
    if (currentPage === "food") path += "?planet=food";
    else if (currentPage === "Freshfish") path += "?planet=Freshfish";
    return path;
  };

  const [selectedHotel, setSelectedHotel] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [searchParam, setSearchParam] = useState<string>("");
  const [showProducts, setShowProducts] = useState<boolean>(false);
  const [suggestionsList, setSuggestionsList] = useState<any[]>([]);
  const [productSearchList, setProductSearchList] = useState<any[]>([]);
  const [selectedCurrentLocation, setSelectedCurrentLocation] =
    useState(location);

  const handleSearchTextChange = async (value: string) => {
    setSearchParam(value);
    try {
      const response = await axiosInstance.post(
        `${API_URL.SUGGESTION_SEARCH}`,
        {
          name: value.trim(),
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          planet_id: "foodPlanet",
        },
        {
          headers: { version: "v3" },
        }
      );
      const suggestions = response.data;

      setSuggestionsList(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestionsList([]);
    }
  };

  const clearData = () => {
    // Keyboard.dismiss();
    setSearchParam("");
    setSuggestionsList([]);
    setShowProducts(false);
    setProductSearchList([]);
  };
  // const getSearchProduct = async (name) => {
  //   Keyboard.dismiss();
  //   setShowProducts(true);
  //   setSearchParam(name);
  //   sendEventToServer({
  //     NAME: 'SEARCH_PRODUCT',
  //     CUSTOMER_ID: customerId,
  //     MODULE: 'SEARCH_PRODUCT',
  //     PAYLOAD: { searchItem: name },
  //     EVENT_CATEGORY: 'INFO',
  //   });
  //   try {
  //     setHideLoading(true);
  //     const response = await axiosInstance.post('product-consumer/admin/master/search/name/starts/byLocation', {
  //       name: name?.trim(),
  //       ...selectedCurrentLocation.coords,
  //       res: 6,
  //       planet_id: 'foodPlanet',
  //     });
  //     setHideLoading(false);
  //     if (response?.data?.hits?.hits?.length > 0) {
  //       let output = response?.data?.hits?.hits?.reduce((result, item) => {
  //         const shopName = item?._source?.shopName;
  //         const existingEntry = result.find((entry) => entry.shopName === shopName);
  //         if (existingEntry) {
  //           existingEntry.data.push(item);
  //         } else {
  //           result.push({
  //             shopName: shopName,
  //             data: [item],
  //             merchantRating: item?._source?.merchantRating || 0,
  //             merchantTotalReviews: item?._source?.merchantTotalReviews || 0,
  //           });
  //         }
  //         return result;
  //       }, []);
  //       setProductSearchList(output);
  //     }
  //   } catch (error) {
  //     setHideLoading(false);
  //     setShowProducts(false);
  //     console.log('error__getSearchProduct', error);
  //   }
  // };
  const [cartPopoverOpen, setCartPopoverOpen] = useState(false);
  const toggleCartPopover = () => setCartPopoverOpen(!cartPopoverOpen);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const subtotal = cartList.reduce(
    (acc: any, item: any) => acc + item.price * item.quantity,
    0
  );

  const path_location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(path_location.search);
    const planet = queryParams.get("Planet");
    if (path_location.pathname === "/") {
      setSelecetdNav("Home");
    } else if (
      path_location.pathname === "/FreshFish" ||
      planet === "fishPlanet" ||
      path_location.pathname === "/FishCategory"
    ) {
      setSelecetdNav("Freshfish");
    } else if (
      path_location.pathname === "/MeatHome" ||
      planet === "meatPlanet" ||
      path_location.pathname === "/MeatCategory" ||
      path_location.pathname === "/Meat"
    ) {
      setSelecetdNav("Poultry");
    } else if (
      path_location.pathname === "/OrganicHome" ||
      planet === "fruitVegPlanet" ||
      path_location.pathname === "/OrganicCategory" ||
      path_location.pathname === "/Organic"
    ) {
      setSelecetdNav("organic");
    } else if (path_location.pathname === "/ComingSoon") {
      setSelecetdNav("organic");
    } else if (path_location.pathname === "/History") {
      setSelecetdNav("history");
    } else {
      setSelecetdNav("");
    }
  }, [path_location.pathname]);

  return (
    <>
      <header className="header-style ">
        {/* <div className="d-flex top-row justify-content-center">
          <div className="row width-full-page">
            <div className="col  text-start">
              <span className="header-re-style">
                Get special deals | Shop now
              </span>
            </div>
            <div className="col loc-container-drop text-end">
              <span className="span-content" onClick={toggleLocationPopup}>
                <img
                  src={location_Icon}
                  className="img-fluid loc-icon me-1"
                  alt="Location"
                />
                Location
              </span>
            </div>
          </div>
        </div> */}
        <Navbar
          color="light"
          light
          expand="lg"
          className="navhead navbar-container"
        >
          <NavbarToggler onClick={Toggle} />

          <NavbarBrand tag={Link} to="/">
            <img
              src={lilo_logo}
              alt="Logo"
              className="mr-3 img-fluid header-logo"
            />
          </NavbarBrand>

          <Collapse
            isOpen={isOpen}
            navbar
            className="collapse-custom text-start"
          >
            <Nav className=" flex-column  flex-lg-row">
              {navbarlinks.map((item) => (
                <>
                  {guestId ? (
                    <>
                      {item.value !== "history" && (
                        <NavItem
                          onClick={() => handleSetActiveNavigation(item.value)}
                        >
                          <NavLink
                            tag={Link}
                            className={`nav-link nav-text relative-div-bg ${
                              selecetedNav === item.value ? "navbaractive" : ""
                            }`}
                            to={item.navlink}
                          >
                            <div
                              className={`bottom-border-active ${
                                selecetedNav === item.value ? "" : "d-none"
                              }`}
                            />
                            {item.label}
                          </NavLink>
                        </NavItem>
                      )}
                    </>
                  ) : (
                    <NavItem
                      onClick={() => handleSetActiveNavigation(item.value)}
                    >
                      <NavLink
                        tag={Link}
                        className={`nav-link nav-text relative-div-bg ${
                          selecetedNav === item.value ? "navbaractive" : ""
                        }`}
                        to={item.navlink}
                      >
                        <div
                          className={`bottom-border-active ${
                            selecetedNav === item.value ? "" : "d-none"
                          }`}
                        />
                        {item.label}
                      </NavLink>
                    </NavItem>
                  )}
                </>
              ))}

              <NavItem>
                <div className="col loc-container-drop">
                  <span
                    className="span-content fluid  location-span line-clamp"
                    onClick={toggleLocationPopup}
                  >
                    <img
                      src={location_Icon}
                      className="img-fluid loc-icon me-1 "
                      alt="Location"
                    />
                    {location?.formattedAddress != null
                      ? location?.formattedAddress
                      : "Location"}
                  </span>
                </div>
              </NavItem>
              <NavItem>
                {/* <div className="dropdown"> */}
                {/* <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm.length > 0 && (
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      {filteredShopNames.map((shop: any) => (
                        <li key={shop.id}>
                          <button className="dropdown-item">
                            {shop.shop_info.shop_name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div> */}

                {/* <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <input
                    className="form-control searcher"
                    type="text"
                    value={searchParam}
                    onChange={(e) => handleSearchTextChange(e.target.value)}
                    placeholder="Search..."
                  />
                  {searchParam ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      onClick={clearData}
                      style={{ marginLeft: "-2rem" }}
                      height="16"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  ) : (
                    <img
                      src={search_icon}
                      alt="Search"
                      style={{ marginLeft: "-2rem" }}
                    />
                  )}
                  <ul className="list-div-search">
                    {suggestionsList?.map((item: any, index: number) => (
                      <li
                        className="bulletless-list"
                        key={index}
                        style={{
                          borderBottom: "1px solid #ccc",
                          padding: "10px 0",
                        }}
                      >
                        <Link
                          to={
                            item.field === "Dish"
                              ? `/Dish/${item.suggestion}`
                              : `/Restaurant/${item.suggestion}`
                          }
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          {item.field}: {item.suggestion}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </NavItem>
            </Nav>
          </Collapse>
          <Nav>
            <NavItem>
              {userIsSignedIn ? (
                <>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggledropdown}
                    className="nav-dropdown-style"
                  >
                    <DropdownToggle
                      nav
                      tag="div"
                      className={` nav-link nav-text d-flex ${
                        selecetedNav === "profile" ? "navbaractive " : ""
                      }`}
                    >
                      <img src={profile_Icon} alt="Profile" />
                      &nbsp;
                      <span className="d-none d-sm-block">
                        {displayName || "Guest"}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      {!guestId && (
                        <DropdownItem
                          tag={Link}
                          to="/Profile"
                          onClick={() => handleSetActiveNavigation("profile")}
                        >
                          Profile
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              ) : (
                <div className="cart">
                  <NavLink
                    tag={Link}
                    to="#"
                    className="nav-link nav-text ps-1"
                    onClick={toggleSignInModal}
                  >
                    <img src={profile_Icon} alt="Profile" /> Sign In
                  </NavLink>
                </div>
              )}
            </NavItem>
            <NavItem>
              {userIsSignedIn ? (
                <div
                  id="Popover1"
                  onMouseEnter={() => setPopoverOpen(true)}
                  onMouseLeave={() => setPopoverOpen(false)}
                  style={{ display: "inline-block" }}
                >
                  <NavLink
                    tag={Link}
                    to={{
                      pathname: "/checkout",
                      search:
                        currentPage === "food" ||
                        currentPage === "Freshfish" ||
                        currentPage === "groceries"
                          ? `?planet=${currentPage}`
                          : "",
                    }}
                    className={`nav-link nav-text badge-position ${
                      selecetedNav === "checkout" ? "navbaractive" : ""
                    }`}
                    onClick={() => handleSetActiveNavigation("checkout")}
                  >
                    <img src={CartIcon} alt="Cart" className="b-cart" />

                    {totalItemCount > 0 && (
                      <span className="badge badge-custom">
                        {totalItemCount}
                      </span>
                    )}
                  </NavLink>
                </div>
              ) : (
                <NavLink
                  tag={Link}
                  className="nav-link nav-text badge-position"
                  onClick={toggleSignInModal}
                >
                  <img src={CartIcon} alt="Cart" />
                  {totalItemCount > 0 && (
                    <span className="badge bg-secondary badge-custom">
                      {totalItemCount}
                    </span>
                  )}
                </NavLink>
              )}
              {/* {userIsSignedIn && (
                <Popover
                  placement="bottom"
                  isOpen={popoverOpen}
                  target="Popover1"
                  toggle={togglePopover}
                  trigger="hover"
                >
                  <PopoverBody>
                    {cartList.length > 0 ? (
                      <ul className="list-unstyled">
                        {cartList.map((item: any, index: number) => (
                          <li key={index} className="checkout-item">
                            <div className="row d-flex">
                              <div className="col">
                                <img
                                  src={item.product_image || foodIcon}
                                  alt=""
                                  className="product-img-pop"
                                />
                              </div>
                              <div className="col">
                                <div>{item.name} </div>
                                <div>{item.weight}</div>
                              </div>
                              <div className="col">₹{item.price}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div>Add items</div>
                    )}
                    <div className="subtotal">
                      <strong>Subtotal: ₹{subtotal.toFixed(2)}</strong>
                    </div>
                    <Button
                      color="primary"
                      onClick={() => navigate("./Checkout")}
                    >
                      Checkout
                    </Button>
                  </PopoverBody>
                </Popover>
              )} */}
            </NavItem>
          </Nav>

          {/* <div>
              {userIsSignedIn ? (
                <Button
                  onClick={handleLogout}
                  className=" logout-button link nav-text"
                >
                  {" "}
                  Logout
                </Button>
              ) : null}
            </div> */}
        </Navbar>

        {isuserConfirmModalOpen && !userIsSignedIn && isGuest && (
          <UserConfirmModal
            isOpen={isuserConfirmModalOpen}
            toggle={toggleuserConfirmModal}
          />
        )}

        {isSignInModalOpen && (
          <SignInModal isOpen={isSignInModalOpen} toggle={toggleSignInModal} />
        )}
        {isMapModalOpen && (
          <LocationPopup
            isOpen={isMapModalOpen}
            onClose={toggleLocationPopup}
            toggle={toggleLocationPopup}
          />
        )}
      </header>
    </>
  );
};

export default MergedHeader;
