import React from "react";
import { Card, CardBody } from "reactstrap";
import {
  fish_demo,
  fish_thorny,
  meat_demo,
  previousordericon,
} from "../../../assets/images";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL, accessToken } from "../../../services/apiConfig";
import "./previousOrder.css";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface PreviousOrder {
  out_of_stock: any;
  product_id: string;
  product_name: string;
  business_area: string;
  product_details: {
    description: string;
    images: string[] | null;
    number_of_pieces: string;
    unit: string;
    measure: string;
  };
  inventories: {
    [key: string]: {
      price: number;
      available_stock: number;
    };
  } | null;
  weight: number;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 932 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 932, min: 568 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 568, min: 0 },
    items: 2,
  },
};

const PreviousOrders = () => {
  const navigate = useNavigate();
  const access_token = accessToken;

  const { data: previousOrders } = useQuery<PreviousOrder[]>(
    "previousOrders",
    async () => {
      const response = await axiosInstance.get<PreviousOrder[]>(
        `${API_URL.PREVIOUS_ORDERS}`
      );
      return response.data;
    }
  );
  const handleImageClick = (product_id: string, business_area: string) => {
    navigate(`/ProductDetails?Planet=${business_area}&id=${product_id}`, {
      state: { product_id },
    });
  };

  const convertWeightToKg = (weight: any) => {
    return Number(weight) >= 1000 ? `${weight / 1000}Kg` : `${weight}g`;
  };

  return (
    <>
      {previousOrders && previousOrders.length > 0 && (
        <>
          <div className="col-md-12 pt-4">
            <div className="sub-div-headings d-flex">
              <img src={previousordericon} alt="previousordericon" />
              &nbsp;
              <span>Previous Orders</span>&nbsp;
              <span className="line-span">
                <div className="line-div-max-length-ecommerce"></div>
              </span>
            </div>
          </div>
          <div className="col-12 pt-3">
            <div className="row">
              <Carousel responsive={responsive} swipeable={true} rewind={true}>
                {Array.isArray(previousOrders) &&
                  previousOrders.map((order) => (
                    <div
                      className="pt-2 mb-3 content-fishcard fish-card-div-height px-2"
                      key={order.product_id}
                      onClick={() =>
                        handleImageClick(
                          order?.product_id,
                          order?.business_area
                        )
                      }
                    >
                      <Card className="stock-card card-hghlght-reverse product-card-cat">
                        <CardBody>
                          {/* <div>
                          {order?.product_details?.images &&
                          order?.product_details?.images?.length > 0 ? (
                            <img
                              src={order?.product_details?.images[0]}
                              alt={order?.product_name}
                              className="img-previousOrder"
                            />
                          ) : (
                            <img src={previousordericon} alt="previousordericon" />
                          )}
                        </div>
                        <div>
                          <div className="inner-text-heading-four pt-2">
                            {order.product_name}
                          </div>
                          <div className="inner-text-content-five">
                            Weight: &nbsp;
                            {order?.weight}
                          </div>
                          <div className="inner-text-content-five">
                            Price: &nbsp; ₹
                            {order.inventories &&
                              order.inventories[
                                Object.keys(order.inventories)[0]
                              ]?.price}
                          </div>
                        </div> */}
                          <div className="card-img-div-top">
                            <img
                              className={`card-img-top `}
                              src={
                                order?.product_details?.images?.[0] ??
                                (order?.business_area === "fishPlanet"
                                  ? fish_demo
                                  : meat_demo)
                              }
                              alt=""
                            />
                          </div>
                          <div className="card-inner-text-div">
                            <div className="inner-text-heading-four one-line-headings">
                              {order?.product_name}
                            </div>
                            {/* <div className="inner-text-content-five-font-12">
                              <span className="">{order?.weight}gm</span>
                            </div> */}
                            {order?.business_area === "meatPlanet" && (
                              <div className="inner-text-content-five-font-12 font-color-gray">
                                {order?.product_details?.measure}
                                {order?.product_details?.unit}
                              </div>
                            )}
                            {order?.business_area === "fishPlanet" && (
                              <div className="inner-text-content-five-font-12">
                                <span className="">
                                  {convertWeightToKg(order?.weight)}
                                  &nbsp;net
                                </span>
                                {order?.product_details?.number_of_pieces && (
                                  <>
                                    <span className="font-color-gray">
                                      &nbsp;|&nbsp;
                                    </span>
                                    <span className="font-color-gray">
                                      {order.product_details?.number_of_pieces}
                                    </span>
                                  </>
                                )}
                              </div>
                            )}
                            <div className="inner-text-content-five">
                              Price :
                              <span className="inner-text-heading-four">
                                {" "}
                                ₹
                                {order.inventories &&
                                  order.inventories[
                                    Object.keys(order.inventories)[0]
                                  ]?.price}
                              </span>
                            </div>
                          </div>

                          {order.out_of_stock ? (
                            <div className="out-of-stock-text">
                              <button className=" outofstock">
                                Out of Stock
                              </button>
                            </div>
                          ) : (
                            <>
                              <div className="mb-0 text-end">
                                <button
                                  className=" add-to-cart-button "
                                  onClick={() =>
                                    handleImageClick(
                                      order?.product_id,
                                      order?.business_area
                                    )
                                  }
                                >
                                  View
                                </button>
                              </div>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PreviousOrders;
